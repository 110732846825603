import { atom } from "recoil";

type LoadingState = {
  loading: boolean;
};

export const loadingState = atom<LoadingState>({
  default: { loading: false },
  key: "loadingState",
});
