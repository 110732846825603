import * as yup from "yup";

yup.setLocale({
  mixed: {
    notType: ({ type }: any) => {
      const types: { [k: string]: string } = {
        number: "数字で",
      };
      return `${types[type] || ""}入力してください`;
    },
    required: ({ value }: any) => {
      if (Array.isArray(value)) {
        return "選択してください";
      }
      return "入力してください";
    },
  },
  number: {
    integer: "整数で入力してください",
    lessThan: "${less}より小さくなければなりません",
    max: "${max}以下で入力してください",
    min: "${min}以上で入力してください",
    moreThan: "${more}より大きくなければなりません",
    negative: "負の数で入力してください",
    positive: "正の数で入力してください",
  },
  string: {
    email: "形式が正しくありません",
    length: "${length}文字で入力してください",
    matches: "一致しません",
    max: "${max}文字以下で入力してください",
    min: "${min}文字以上で入力してください",
    url: "形式が正しくありません",
  },
});

export default yup;
