import { atom } from "recoil";

type ErrorState = {
  errorMessage: String | null;
  unauthorized: boolean;
};

export const errorState = atom<ErrorState>({
  default: { errorMessage: null, unauthorized: false },
  key: "errorState",
});
