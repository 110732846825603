import { Badge, BadgeProps } from "@chakra-ui/react";
import React from "react";

export type RequireLabelProps = {
  isEitherRequired?: boolean;
  isOptional?: boolean;
  isRequired?: boolean;
};

export const RequireLabel: React.VFC<RequireLabelProps & BadgeProps> = ({
  isEitherRequired,
  isRequired,
  isOptional,
  ...props
}) => {
  const colorScheme = () => {
    if (isRequired) return "brand";
    if (isOptional) return "gray";
    if (isEitherRequired) return "cyan";
  };

  const renderLabel = () => {
    if (isRequired === true) return "必須";
    if (isRequired === false || isOptional === true) return "任意";
    if (isEitherRequired) return "どちらか必須";
  };

  if (!isRequired && !isOptional && !isEitherRequired) return null;

  return (
    <Badge colorScheme={colorScheme()} fontSize="sm" px={2} rounded="xl" {...props}>
      {renderLabel()}
    </Badge>
  );
};
