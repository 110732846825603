import { Box, BoxProps, forwardRef } from "@chakra-ui/react";
import React from "react";

type Props = {
  bodyProps?: BoxProps;
  noBody?: boolean;
  title?: string;
};

export const Card: React.VFC<Props & BoxProps> = forwardRef(
  ({ bodyProps, children, title, noBody = false, ...props }, ref) => {
    return (
      <Box bg="white" borderWidth={1} overflow={"hidden"} ref={ref} rounded="md" {...props}>
        {title && (
          <Box bg={"gray.100"} fontWeight="bold" px={4} py={2}>
            {title}
          </Box>
        )}
        {noBody && children}
        {!noBody && (
          <Box p={4} {...bodyProps}>
            {children}
          </Box>
        )}
      </Box>
    );
  }
);

Card.displayName = "Card";
