import { ApolloProvider } from "@apollo/client";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Center,
  ChakraProvider,
  Container,
  Flex,
  Spinner,
  Box,
} from "@chakra-ui/react";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";
import { useTranslation } from "react-i18next";
import { RecoilRoot, useRecoilValue, useRecoilState } from "recoil";
import { RecoilDevTools } from "recoil-gear";
import RecoilNexus from "recoil-nexus";

import { useFirebase, useLogout } from "~/libs/firebase";
import theme from "~/theme";
import { Loading } from "~data-display/Loading";
import { useMeQuery } from "~generated/graphql";
import { apolloClient } from "~graphql/client";
import { useConfirm } from "~hooks/useConfirm";
import { Header } from "~layouts/Header";
import { AdminLayout } from "~layouts/admin/AdminLayout";
import { errorState } from "~recoil/atoms/errorState";
import { firebaseUserState } from "~recoil/atoms/firebaseUserState";
import { loadingState } from "~recoil/atoms/loadingState";
import { userState } from "~recoil/atoms/userState";
import { Login } from "~standalone/Login";

import "~libs/font-awesome";
import "~libs/i18n";
import "~libs/react-datepicker";
import "~libs/yup";
import "~/styles/application.scss";

function MyApp(props: AppProps) {
  const { t } = useTranslation();
  return (
    <>
      <Head>
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta content="noindex" name="robots" />
      </Head>
      <RecoilRoot>
        <RecoilNexus />
        <ChakraProvider theme={theme}>
          <ApolloProvider client={apolloClient}>
            <Head>
              <title>{t("site.name")}</title>
            </Head>
            <Flex flexDirection="column" w="full">
              <Header />
              <Container maxW="container.xl" mt={4} pb={10} pt={12}>
                <Page {...props} />
              </Container>
            </Flex>
          </ApolloProvider>
        </ChakraProvider>
        {process.env.NODE_ENV === "development" && <RecoilDevTools />}
      </RecoilRoot>
    </>
  );
}
export default MyApp;

const Page = ({ Component, pageProps }: AppProps) => {
  useFirebase();

  const confirm = useConfirm();
  const logout = useLogout();
  const router = useRouter();

  const { firebaseUser } = useRecoilValue(firebaseUserState);
  const { loading } = useRecoilValue(loadingState);
  const [error, setError] = useRecoilState(errorState);
  const [{ user }, setUser] = useRecoilState(userState);

  const { data } = useMeQuery({ skip: !firebaseUser });

  React.useEffect(() => {
    setUser({ user: data?.me });
  }, [data?.me, setUser]);

  React.useEffect(() => {
    if (!error.errorMessage) return;

    (async () => {
      await confirm(error.errorMessage, { okOnly: true, title: "エラー" });
      if (error.unauthorized) {
        await logout();
      }

      setError({ errorMessage: null, unauthorized: false });
    })();
  }, [confirm, error, logout, setError]);

  const isAdminPage = router.pathname.startsWith("/admin");

  if (!router.pathname.startsWith("/public")) {
    if (firebaseUser === undefined || (firebaseUser && !user)) {
      return <Loading />;
    }

    if (firebaseUser === null) {
      return <Login />;
    }

    if (isAdminPage) {
      if (!user) {
        return <Loading />;
      } else if (!user.group.admin) {
        router.replace("/");
        return null;
      }
    }
  }

  return (
    <>
      {isAdminPage && (
        <AdminLayout>
          <Component {...pageProps} />
        </AdminLayout>
      )}
      {!isAdminPage && <Component {...pageProps} />}

      <Modal isCentered isOpen={loading} onClose={() => null} size="xs">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Center>
              <Box py={8} textAlign="center">
                <Spinner size="xl" />
                <Box fontWeight="bold" mt={8}>
                  Loading...
                </Box>
              </Box>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
