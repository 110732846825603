import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";
import { createUploadLink } from "apollo-upload-client";
import { getRecoil, setRecoil } from "recoil-nexus";

import { errorState } from "~recoil/atoms/errorState";
import { firebaseUserState } from "~recoil/atoms/firebaseUserState";

const httpLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
}) as unknown as ApolloLink;

const authLink = setContext(async (_, { headers }) => {
  const { firebaseUser } = getRecoil(firebaseUserState);
  const token = await firebaseUser?.getIdToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const isValidationError = (e: any) => {
  return !!e.graphQLErrors?.[0]?.extensions?.validation;
};

const isUnauthorizedError = (e: any) => {
  return !!e.graphQLErrors?.[0]?.extensions?.unauthorized;
};

const errorLink = onError((error) => {
  Sentry.withScope((scope) => {
    scope.setExtra("operationName", error.operation.operationName);
    scope.setExtra("query", error.operation.query.loc?.source?.body?.trim());
    scope.setExtra("variables", JSON.stringify(error.operation.variables, null, "  "));

    if (error.networkError) {
      scope.setExtra("networkError", JSON.stringify(error.networkError, null, "  "));
      setRecoil(errorState, {
        errorMessage: "通信エラーが発生しました",
        unauthorized: false,
      });
      Sentry.captureMessage(`Network Error: ${error.operation.operationName}`);
    } else if (error.graphQLErrors) {
      scope.setExtra("graphQLErrors", JSON.stringify(error.graphQLErrors, null, "  "));
      scope.setExtra("operation", JSON.stringify(error.operation, null, "  "));
      scope.setExtra("response", JSON.stringify(error.response, null, "  "));

      if (isValidationError(error)) {
        setRecoil(errorState, {
          errorMessage: error.graphQLErrors?.[0]?.message || "バリデーションエラー",
          unauthorized: false,
        });
      } else if (isUnauthorizedError(error)) {
        setRecoil(errorState, {
          errorMessage: error.graphQLErrors[0].message,
          unauthorized: true,
        });
      } else {
        setRecoil(errorState, {
          errorMessage: error.graphQLErrors?.[0]?.message || "システムエラーが発生しました",
          unauthorized: false,
        });
        Sentry.captureMessage(`GraphQL Error: ${error.operation.operationName}`);
      }
    }
  });
});

export const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      notifyOnNetworkStatusChange: true,
    },
  },
  link: ApolloLink.from([authLink, errorLink, httpLink]),
});
