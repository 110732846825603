import { atom } from "recoil";

import { UserFragment } from "~generated/graphql";

type UserState = {
  user: UserFragment | null | undefined;
};

export const userState = atom<UserState>({
  default: { user: undefined },
  key: "userState",
});
