import { FormLabel, FormLabelProps } from "@chakra-ui/react";
import React from "react";

import { RequireLabel, RequireLabelProps } from "~form/RequireLabel";

type Props = RequireLabelProps;

export const MyFormLabel: React.VFC<Props & FormLabelProps> = ({
  isEitherRequired,
  isRequired,
  isOptional,
  children,
  ...props
}) => {
  return (
    <FormLabel alignItems="center" display="flex" {...props}>
      <RequireLabel isEitherRequired={isEitherRequired} isOptional={isOptional} isRequired={isRequired} mr={1} />
      {children}
    </FormLabel>
  );
};
