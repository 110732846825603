import { Box, Divider, Flex, Stack } from "@chakra-ui/react";
import { faTag, faUsers } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "~data-display/Card";
import { pagesPath } from "~generated/$path";
import { AdminLayoutMenuItem } from "~layouts/admin/AdminLayoutMenuItem";

export const AdminLayout: React.FC = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Flex gap={8} mt={4}>
      <Box h="calc(100vh - 100px)" position={"sticky"} top={"5rem"}>
        <Card bodyProps={{ p: 0 }} title="管理画面" w={64}>
          <Stack divider={<Divider />} overflow="hidden" spacing={0}>
            <AdminLayoutMenuItem
              href={pagesPath.admin.status_conditions.$url()}
              icon={faTag}
              label={t("model.statusCondition.modelName")}
            />

            <AdminLayoutMenuItem
              href={pagesPath.admin.groups.$url()}
              icon={faUsers}
              label={t("model.group.modelName")}
            />
          </Stack>
        </Card>
      </Box>
      <Box flexGrow={1}>{children}</Box>
    </Flex>
  );
};
