import { format as dateFormat, isValid } from "date-fns";
import i18next, { use } from "i18next";
import numeral from "numeral";
import { initReactI18next } from "react-i18next";

import ja from "~libs/i18n/ja";

use(initReactI18next).init({
  interpolation: {
    format: (value, format) => {
      if (format === "yen") {
        return value !== null && value !== undefined ? numeral(value).format("0,0") : "";
      }

      if (value instanceof Date && isValid(value)) {
        return value && format ? dateFormat(value, format) : "";
      }
      return value;
    },
  },
  lng: "ja",
  resources: {
    ja: {
      translation: ja,
    },
  },
});

export default i18next;
