import type { Query as Query0 } from '../pages/public/register'

export const pagesPath = {
  account: {
    $url: (url?: { hash?: string }) => ({ pathname: '/account' as const, hash: url?.hash })
  },
  admin: {
    groups: {
      _id: (id: string | number) => ({
        users: {
          $url: (url?: { hash?: string }) => ({ pathname: '/admin/groups/[id]/users' as const, query: { id }, hash: url?.hash })
        }
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/admin/groups' as const, hash: url?.hash })
    },
    status_conditions: {
      $url: (url?: { hash?: string }) => ({ pathname: '/admin/status-conditions' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/admin' as const, hash: url?.hash })
  },
  public: {
    password_reset: {
      $url: (url?: { hash?: string }) => ({ pathname: '/public/password-reset' as const, hash: url?.hash })
    },
    register: {
      $url: (url: { query: Query0, hash?: string }) => ({ pathname: '/public/register' as const, query: url.query, hash: url.hash })
    }
  },
  shipments: {
    _id: (id: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/shipments/[id]' as const, query: { id }, hash: url?.hash })
    }),
    csv: {
      $url: (url?: { hash?: string }) => ({ pathname: '/shipments/csv' as const, hash: url?.hash })
    },
    new: {
      $url: (url?: { hash?: string }) => ({ pathname: '/shipments/new' as const, hash: url?.hash })
    }
  },
  $url: (url?: { hash?: string }) => ({ pathname: '/' as const, hash: url?.hash })
}

export type PagesPath = typeof pagesPath

export const staticPath = {
  android_chrome_192x192_png: '/android-chrome-192x192.png',
  android_chrome_512x512_png: '/android-chrome-512x512.png',
  apple_touch_icon_png: '/apple-touch-icon.png',
  browserconfig_xml: '/browserconfig.xml',
  favicon_16x16_png: '/favicon-16x16.png',
  favicon_32x32_png: '/favicon-32x32.png',
  favicon_ico: '/favicon.ico',
  hukuyama_png: '/hukuyama.png',
  mstile_150x150_png: '/mstile-150x150.png',
  robots_txt: '/robots.txt',
  safari_pinned_tab_svg: '/safari-pinned-tab.svg',
  sagawa_png: '/sagawa.png',
  seino_jpg: '/seino.jpg',
  site_webmanifest: '/site.webmanifest',
  yamato_svg: '/yamato.svg',
  you_pack_png: '/you-pack.png'
} as const

export type StaticPath = typeof staticPath
