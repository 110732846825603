import { Box, Container, Flex, Heading } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { NextLink } from "~/components/alias/NextLink";
import { pagesPath } from "~generated/$path";
import { AccountMenu } from "~layouts/AccountMenu";

export const Header: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <Flex
      alignItems="center"
      as="header"
      bgColor="primary"
      h={12}
      left={0}
      pos="fixed"
      right={0}
      top={0}
      zIndex="sticky"
    >
      <Container display="flex" maxW="container.xl">
        <NextLink href={pagesPath.$url()} passHref>
          <Box as="a">
            <Heading as="h1" color="white" fontSize="2xl" fontWeight="normal">
              {t("site.name")}
            </Heading>
          </Box>
        </NextLink>
        <AccountMenu ml="auto" />
      </Container>
    </Flex>
  );
};
