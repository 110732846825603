import defaultTheme from "@chakra-ui/theme";

export const colors = {
  body: defaultTheme.colors.gray["800"],
  brand: defaultTheme.colors.cyan,
  emphasis: "#6834f8",
  error: defaultTheme.colors.red["500"],
  muted: defaultTheme.colors.gray["500"],
  primary: defaultTheme.colors.cyan["500"],
};
