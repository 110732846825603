import invert from "lodash/invert";
import pick from "lodash/pick";

import { ShipmentStatusEnum } from "~generated/graphql";

const shipment = {
  createdAt: "登録日時",
  deliveredAt: "配達完了日時",
  deliveryAlert: "配達完了アラート",
  deliveryAlertOn: "配達完了アラートを出す日",
  fetchedAt: "データ取得日時",
  general1: "汎用カラム1",
  general2: "汎用カラム2",
  general3: "汎用カラム3",
  general4: "汎用カラム4",
  general5: "汎用カラム5",
  id: "ID",
  latestTrackingStatus: "配送ステータス",
  receiveAlertEmail: "アラートメール",
  receiveArchiveEmail: "アーカイブメール",
  shippedAt: "出荷日時",
  shippingAlert: "出荷アラート",
  shippingAlertOn: "出荷アラートを出す日",
  status: "ステータス",
  statusOptions: [
    { label: "データ未取得", value: "initial" },
    { label: "未登録", value: "not_registered" },
    { label: "出荷済み", value: "shipped" },
    { label: "配達完了", value: "delivered" },
    { label: "調査中", value: "researching" },
    { label: "エラー", value: "error" },
    { label: "未定義", value: "undefined" },
  ],
  trackingNumber: "送り状番号",
  type: "配送会社",
  typeOptions: [
    { label: "ヤマト運輸", value: "Yamato" },
    { label: "ゆうパック", value: "YouPack" },
    { label: "佐川運輸", value: "Sagawa" },
    { label: "西濃運輸", value: "Seino" },
    { label: "福山通運", value: "Hukuyama" },
  ],
};

const statusCondition = {
  matchType: "一致方法",
  matchTypeOptions: [
    { label: "完全一致", value: "exact" },
    { label: "前方一致", value: "forward" },
  ],
  modelName: "ステータス条件",
  status: "ステータス",
  value: "値",
};

const group = {
  aasmState: "ステータス",
  aasmStateOptions: [
    { label: "active", value: "active" },
    { label: "inactive", value: "inactive" },
  ],
  admin: "管理グループ",
  createdAt: "作成日時",
  modelName: "グループ",
  name: "グループ名",
  usersCount: "ユーザー数",
};

const user = {
  createdAt: "登録日時",
  email: "メールアドレス",
  firebaseUid: "Firebase UID",
  modelName: "ユーザー",
};

const csv = {
  ...pick(shipment, ["type", "trackingNumber", "shippingAlertOn", "deliveryAlertOn"]),
};

export default {
  csv,
  csvInvert: invert(csv),
  format: {
    date: {
      default: "{{- date, yyyy/MM/dd}}",
      yearMonth: "{{- date, yyyy年MM月}}",
    },
    datetime: {
      default: "{{- datetime, yyyy/MM/dd HH:mm}}",
    },
    yen: "{{yen, yen}}円",
  },
  model: { group, shipment, statusCondition, user },

  site: { name: "My Logi" },
};

export const isShipmentStatus = (value: any): value is ShipmentStatusEnum => {
  return Object.values(ShipmentStatusEnum).includes(value as ShipmentStatusEnum);
};
