import { User } from "@firebase/auth";
import { atom } from "recoil";

type FirebaseUserState = {
  firebaseUser: User | null | undefined;
};

export const firebaseUserState = atom<FirebaseUserState>({
  dangerouslyAllowMutability: true,
  default: { firebaseUser: undefined },
  key: "firebaseUserState",
});
