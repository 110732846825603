import { Center, Spinner } from "@chakra-ui/react";
import React from "react";

export const Loading: React.VFC = () => {
  return (
    <Center h="80vh">
      <Spinner />
    </Center>
  );
};
